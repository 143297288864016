.statblock-container {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: inherit;
  max-width: inherit;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.statblock-container::-webkit-scrollbar {
  display: none;
}

.statblock-container__inner {
  margin: 3rem auto;
  margin-bottom: 10rem;
}

.statblock-container__inner--export {
  margin: 5rem;
}

.statblock {
  background: url("_assets/img/stat-block-top-texture.png"),
    url("_assets/img/paper-texture.png");
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat, repeat;
  padding: 0 0.9rem;
  position: relative;
  max-width: 500px;
  margin: 0 10px;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
}

.statblock--export {
  margin: 0;
}

.statblock::before {
  content: "";
  display: block;
  background: url("_assets/img/stat-bar-book.png") center;
  background-size: 100% 100%;
  height: 6px;
  position: absolute;
  left: -4px;
  right: -4px;
  top: -5px;
}

.statblock::after {
  content: "";
  display: block;
  background: url("_assets/img/stat-bar-book.png") center;
  background-size: 100% 100%;
  height: 6px;
  position: absolute;
  left: -4px;
  right: -4px;
  bottom: -5px;
}

.statblock__header {
  padding: 1rem 0.25rem;
}

.statblock__header h1 {
  font-family: "Vollkorn", Georgia, serif;
  color: #922610;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  font-variant: small-caps;
  font-weight: 600;
  margin: 0;
}

.statblock__header h2 {
  font-weight: normal;
  font-style: italic;
  font-size: 0.9rem;
  line-height: 1.2em;
  margin: 0;
}

.statblock__section {
  padding: 16px 4px;
  border-top: 3px solid #922610;
}

.statblock__section--with-heading {
  padding: 0 4px 16px 4px;
  border: none;
}

.statblock__section--with-heading h3 {
  color: #922610;
  font-size: 1.35rem;
  margin: 0;
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #922610;
}

.statblock__property-name {
  font-weight: 900;
  letter-spacing: 0.5px;
  display: inline-block;
  margin-right: 0.2rem;
}

.statblock__property {
  line-height: 1.3rem;
}

.statblock__property--block {
  margin-bottom: 0.3rem;
}

.ability {
  text-align: center;
  display: inline-block;
  width: 16%;
}

.ability h4 {
  font-size: 0.9rem;
  color: #922610;
  font-weight: 900;
  margin: 0 0 2px 0;
  text-transform: uppercase;
}

.red {
  color: #922610;
}

.italic {
  font-style: italic;
}

h4.form-header {
  color: #922610;
  position: relative;
  font-size: 0.9rem;
}

.statblock label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.dex-check {
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px auto;
  border-radius: 14px;
  border: 4px solid #dedede;
  box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.5);
}

.dex-check.checked {
  background-color: #922610;
  border-color: #bbb;
}

.btn-primary {
  background-color: #4d1308;
  border-color: #4d1308;
}

.btn-primary:hover {
  background-color: #922610;
  border-color: #922610;
}

.delete-btn {
  position: absolute;
  right: -5px;
  top: -5px;
  color: #ddd;
  font-size: 1rem;
  z-index: 100;
  border: none;
  background: transparent;
  cursor: pointer;
  border: 1px solid #ffffff;
  transition: all 0.2s ease;
}

.delete-btn:hover {
  color: #922610;
}

#exportInstructions {
  position: fixed;
  left: 40rem;
  top: 7rem;
  color: #999999;
  font-size: 2rem;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  #root {
    background-color: transparent;
  }
  #export-instructions,
  nav {
    display: none;
  }
}

.rbt-input {
  padding: 0.5rem;
  border: 1px solid rgba(209, 213, 219);
  border-radius: 0.375rem;
}

.dropdown-menu {
  display: block;
  background-color: white;
  border: 1px solid rgba(209, 213, 219);
  border-radius: 0.375rem;

  position: absolute;
  display: block;
  max-height: 300px;
  overflow: auto;
  will-change: transform;
  width: 538.333px;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 33px, 0px);
  z-index: 10;
}
.dropdown-item {
  display: block;
  padding: 0.25rem 0 0 1rem;
}

.dropdown-item:hover {
  background-color: rgb(222, 226, 231);
}

.rbt-token {
  background-color: #fee2e2 !important;
  color: #7f1d1d !important;
}
