.accordion {
  margin: 0.5rem 0;
  position: relative;
}

.accordion__inner {
  display: block;
  margin-top: 0.25rem;
  padding: 0.75rem;
  padding-top: 0;
}

.accordion__button {
  padding: 0.75rem;

  background-color: white;
  border: none;
  text-align: left;

  color: #922610;
  cursor: pointer;
  display: block;
  font-size: 1.2rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

.accordion__button:hover .accordion__arrow {
  opacity: 1;
}

.accordion__arrow {
  opacity: 0.2;
  float: right;
  margin-right: 0.25rem;
  margin-top: 0.25rem;

  transition: opacity 0.3s ease;
}
