.c-toast {
  min-height: 44px;
  padding-left: 1rem;
  color: black;
  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.35); }
  .c-toast:first-child {
    margin-top: 1rem; }

.Toastify__toast--success {
  background-color: #048535 !important;
  color: white !important; }

.Toastify__toast--default {
  color: black !important; }

.Toastify__toast--error {
  color: white !important; }

.c-statbox {
  position: relative;
  text-align: center; }
  .c-statbox__stat {
    display: block;
    font-size: 1.3333rem;
    margin: 0;
    padding-top: 0.25rem;
    line-height: 1;
    font-family: "Vollkorn", Times, serif; }
  .c-statbox__title {
    max-width: 60px;
    margin: 0 auto;
    display: block;
    font-size: 0.6rem;
    text-transform: uppercase;
    border-top: 1px solid #a2a2a2; }
  .c-statbox__select {
    border: none; }
  .c-statbox--large {
    font-weight: bolder; }
    .c-statbox--large .c-statbox__title {
      font-size: 0.75rem;
      max-width: 80px;
      line-height: 2; }
    .c-statbox--large .c-statbox__stat {
      font-size: 1.75rem; }
  .c-statbox--secondary {
    max-width: 20px;
    border: none; }
    .c-statbox--secondary .c-statbox__title {
      border: none;
      font-style: italic; }
