.feature-block {
  margin: 0 0.25rem 1rem 0rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(209, 213, 219);
  position: relative;
  border-radius: 0 0.387rem 0.385rem 0;
}

.feature-block::before {
  content: "";
  position: absolute;
  background-color: rgba(127, 29, 29);
  width: 4px;
  top: -1px;
  bottom: -1px;
  left: -1px;
}
