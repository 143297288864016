.a-slide-enter {
  overflow: hidden;
  max-height: 0;
}
.a-slide-enter-active {
  max-height: 50vh;

  transition: transform 400ms;
  transition: max-height 400ms;
}
.a-slide-exit {
  max-height: 50vh;
  overflow: hidden;
}
.a-slide-exit-active {
  max-height: 0;
  overflow: hidden;

  transition: transform 400ms;
  transition: max-height 400ms;
}
