@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("//fonts.googleapis.com/css?family=Lato:100,200,300,400,500,600,700,800,900|Vollkorn:400,600,700");
@import "_assets/css/main.css";
@import "./styles/animations.css";

html,
body,
#root {
  font-family: "Lato", Helvetica, sans-serif;
  min-height: 100vh;
  position: relative;
  line-height: 125%;
}

* {
  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(243, 244, 246, 1);
}

body {
  overflow-x: hidden;
  overflow: overlay;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.scroller {
  overflow-y: auto;
  overflow-y: overlay;
}

.scroller::-webkit-scrollbar {
  width: 8px;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.fancy-font {
  font-family: "Vollkorn", Georgia, Times, serif;
}

option {
  background-color: white;
  color: black;
}
