$inknut-font: "Vollkorn", Times, serif;

// Pathfinder Colors
$pf-maroon: rgb(95, 7, 0);
$pf-beige: rgb(216, 196, 133);
$pf-light-gray: rgb(209, 210, 211);
$pf-navy: #032863;
$pf-light-blue: rgb(229, 239, 250);

// D&D Colors
$dnd-maroon: #4d1307;

// Font Colors
$color-n1: #f3f3f3;
$color-n2: #ddd;
$color-n3: #bebebe;
$color-n4: #a2a2a2;
$color-n5: #898989;
$color-n6: #727272;
$color-n7: #5d5d5d;
$color-n8: #484848;
$color-n9: #333333;
